import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationPin, faUser} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-solid-svg-icons";
import { useTranslation} from "gatsby-plugin-react-i18next";
import {Link} from 'gatsby-plugin-react-i18next';

const ScheduleDateOrders = (props) => {
    const {t} = useTranslation();


    return (
<div>

    {props.dateOrders.map(order => (
        <div key={order.id} className={'notification'} >



            {order.split_order_id !== null ? <div className={'notification is-warning'}>{t('auto_split_by_system')}</div> :  null}

            <p><span className="icon  mr-1">
                <FontAwesomeIcon icon={faLocationPin}/>
                        </span> {order.customer_address.formatted_address}</p>
            <p><span className="icon  mr-1">
                <FontAwesomeIcon icon={faClock}/>
                        </span>{order.arrival_at_window}</p>

            <p><span className="icon  mr-1">
                <FontAwesomeIcon icon={faUser}/>
                        </span>{order.user.first_name} {order.user.last_name} ({order.user.phone_with_code})</p>
            <h3>{t('vehicles')}:</h3>
            <ul>
            {order.order_vehicles.map(
                vehicle => (
                    <li  key={vehicle.id}><b>{vehicle.customer_vehicle.make_and_model} - ({vehicle.customer_vehicle.reg_number})</b></li>
                )
            )}
            </ul>

            <Link className={'button is-info'}

                  to={'/view-order?id=' + order.id}

            >{t('view')}</Link>

        </div>

    ))}

</div>
    );

};
export default ScheduleDateOrders;

/*

           state={{ orderId: order.id }}

  to={`/view-order/`}*/